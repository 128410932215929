import React, { Component } from 'react'
import * as Scroll from 'react-scroll'

import Menu from 'src/components/Menu/v3'
import FanMenu from 'src/components/Menu/v2/Fan'
import MenuDesktop from 'src/components/Menu/v2/Desktop'
import FanMenuDesktop from 'src/components/Menu/v2/Desktop/Fan'

import { getTelFormatWithCountryCode } from 'static/utils'
import { TEL } from 'static/config/variables'

import './style.scss'

let scroll = Scroll.animateScroll

export default class Header extends Component {
  componentDidMount() {
    var navbar = document.getElementById('navbar')
    var sticky = navbar.offsetTop

    if (window.pageYOffset >= sticky) {
      navbar.classList.add('sticky')
    } else {
      navbar.classList.remove('sticky')
    }
  }

  getMobileMenu = () => {
    const { menuName, onClickMenu, activeMenu } = this.props
    switch (menuName) {
      case 'all-fan':
        return <FanMenu />
      default:
        return <Menu onClickMenu={onClickMenu} activeMenu={activeMenu} />
    }
  }

  getDesktopMenu = () => {
    const { menuName } = this.props
    switch (menuName) {
      case 'all-fan':
        return <FanMenuDesktop />
      default:
        return <MenuDesktop />
    }
  }

  scrollToTop = () => {
    scroll.scrollToTop()
  }

  render() {
    return (
      <header className="header" id="navbar">
        <nav>
          <div className="brand-wrapper desktop">
            <div className="brand-box">
              <div onClick={this.scrollToTop} style={{ cursor: 'pointer' }}>
                <img
                  className="img-responsive"
                  src="https://d3u85r51ph21nf.cloudfront.net/static/logo_tanjai_v3.png"
                  alt="แทนใจ"
                />
              </div>
            </div>
            {this.getDesktopMenu()}
            <div className="button-box">
              <div>
                <div className="call-button">
                  <img src="https://d3u85r51ph21nf.cloudfront.net/static/phone.png" alt={TEL} />
                  <a
                    id="header-call-mobile"
                    href={`tel:${getTelFormatWithCountryCode(TEL)}`}
                    title={TEL}
                  >
                    {TEL}
                  </a>
                </div>
              </div>
              <div className="button">
                <img src="https://d3u85r51ph21nf.cloudfront.net/static/line.png" alt="@tanjai.co" />
                <a
                  href="https://lin.ee/6Bs20SI?tanjai=A7"
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`navbar-line`}
                  title="@tanjai.co"
                >
                  <span>สั่งซื้อพวงหรีด</span>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="menu-box mobile">{this.getMobileMenu()}</div> */}
        </nav>
      </header>
    )
  }
}
