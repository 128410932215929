export const getTelFormatWithCountryCode = tel => {
  return tel.replace(/^(?:0)/, '+66')
}

export const colorDefalut = {
  white: {
    nameTh: 'สีขาว',
    nameEn: 'white',
    sameColor: ['white'],
    colorHex: '#F0F0F0',
    tone: ['smart']
  },
  red: { nameTh: 'สีแดง', nameEn: 'red', sameColor: ['red'], colorHex: '#FFE3E3', tone: ['hot'] },
  yellow: {
    nameTh: 'สีเหลือง',
    nameEn: 'yellow',
    sameColor: ['yellow'],
    colorHex: '#FEFFE0',
    tone: ['hot']
  },
  orange: {
    nameTh: 'สีส้ม',
    nameEn: 'orange',
    sameColor: ['orange'],
    colorHex: '#FFE8D3',
    tone: ['hot']
  },
  pink: {
    nameTh: 'สีชมพู',
    nameEn: 'pink',
    sameColor: ['pink', 'peach'],
    colorHex: '#FFEFFF',
    tone: ['cool']
  },
  peach: {
    nameTh: 'สีชมพู',
    nameEn: 'pink',
    sameColor: ['pink', 'peach'],
    colorHex: '#FFEFFF',
    tone: ['cool']
  },
  blue: {
    nameTh: 'สีฟ้า',
    nameEn: 'blue',
    sameColor: ['blue'],
    colorHex: '#F0FCFF',
    tone: ['cool']
  },
  green: {
    nameTh: 'สีเขียว',
    nameEn: 'green',
    sameColor: ['green'],
    colorHex: '#E7FFDC',
    tone: ['smart']
  },
  purple: {
    nameTh: 'สีม่วง',
    nameEn: 'purple',
    sameColor: ['purple', 'violet'],
    colorHex: '#F5EEFF',
    tone: ['cool']
  },
  violet: {
    nameTh: 'สีม่วง',
    nameEn: 'purple',
    sameColor: ['purple', 'violet'],
    colorHex: '#F5EEFF',
    tone: ['cool']
  },
  sand: {
    nameTh: 'สีครีม',
    nameEn: 'sand',
    sameColor: ['sand'],
    colorHex: '#FFF8EC',
    tone: ['smart']
  },
  other: {
    nameTh: 'ทั้งหมด',
    nameEn: 'other',
    sameColor: ['colorful'],
    colorHex:
      'linear-gradient(145deg, #C9FCFF 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #F6FE9C 0%, rgba(255, 255, 255, 0) 100%), #FFDFDF',
    tone: ['other']
  }
}

export const setRunning = v => {
  let output = 0
  switch (v) {
    case 'product-a':
      output = 1
      break
    case 'product-b':
      output = 1
      break
    case 'product-p':
      output = 1
      break
    case 'product-f':
      output = 1
      break
    default:
      break
  }
  return output
}
