import React, { Component } from 'react'
import Scroll from 'react-scroll'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

import CentralData from './central.json'
import './style.scss'

const scroller = Scroll.scroller

export default class Central extends Component {
  state = {
    activeMenu: ''
  }

  onSelectMenu = menu => {
    const { activeMenu } = this.state
    if (menu === activeMenu) {
      this.setState({
        activeMenu: ''
      })
    } else {
      this.setState({
        activeMenu: menu
      })
    }
  }

  onScrollTo = ele => {
    const offset = document.getElementById('navbar').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  render() {
    const { activeMenu } = this.state
    const { onClickSubMenu } = this.props

    return (
      <div className="service-areas-menu">
        <div
          className={`${activeMenu === 'active' ? 'active' : ''} header-box`}
          onClick={() => this.onSelectMenu('active')}
        >
          <span>ภาคกลาง</span>
          <span>{activeMenu === 'active' ? <UpOutlined /> : <DownOutlined />}</span>
        </div>
        {activeMenu === 'active' && (
          <div className="content-box">
            {CentralData.map((item, index) => {
              return (
                <a className="content-item" href={item.url} key={index}>
                  <span>&bull;</span>
                  <span>{item.name}</span>
                </a>
              )
            })}
            <a
              className="content-item full-width"
              onClick={() => {
                this.onScrollTo('serviceAreas')
                onClickSubMenu('service-areas')
              }}
            >
              <span>ดูทั้งหมด</span>
            </a>
          </div>
        )}
      </div>
    )
  }
}
