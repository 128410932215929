import React, { Component } from 'react'
import Scroll from 'react-scroll'

import './style.scss'

const scroller = Scroll.scroller

export default class ReedFlower extends Component {
  onScrollTo = ele => {
    const offset = document.getElementById('navbar').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  render() {
    const { onClickSubMenu, className } = this.props
    return (
      <div className={`${className} sub-menu-wrapper left`}>
        <div className="sub-menu-box">
          <div
            className="sub-menu-item reed top"
            onClick={() => {
              this.onScrollTo('productPremium')
              onClickSubMenu('flower')
            }}
            onKeyDown={() => {
              this.onScrollTo('productPremium')
              onClickSubMenu('flower')
            }}
          >
            พวงหรีดพรีเมียม
          </div>
          <div
            className="sub-menu-item reed"
            onClick={() => {
              this.onScrollTo('productB')
              onClickSubMenu('flower')
            }}
            onKeyDown={() => {
              this.onScrollTo('productB')
              onClickSubMenu('flower')
            }}
          >
            พวงหรีดมาตราฐาน <span className="custom-badge">ยอดนิยม</span>
          </div>
          <div
            className="sub-menu-item reed"
            onClick={() => {
              this.onScrollTo('productA')
              onClickSubMenu('flower')
            }}
            onKeyDown={() => {
              this.onScrollTo('productA')
              onClickSubMenu('flower')
            }}
          >
            พวงหรีดราคาประหยัด
          </div>
        </div>
      </div>
    )
  }
}
