import React, { Component } from 'react'
import Scroll from 'react-scroll'
import throttle from 'lodash.throttle'

import ReedFlower from '../SubMenu/ReedFlower'
import ServiceAreas from '../SubMenu/ServiceAreas'

import './style.scss'

const scroller = Scroll.scroller

export default class MenuDesktop extends Component {
  state = {
    activeMenu: '',
    isOpenReedFlowerSubMenu: false,
    isOpenServiceAreasSubMenu: false
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false)
    document.addEventListener('scroll', throttle(this.closeMenu, 500, { leading: true }), false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false)
    document.removeEventListener('scroll', throttle(this.closeMenu, 500, { leading: true }), false)
  }

  closeMenu = () => {
    this.setState({
      activeMenu: '',
      isOpenReedFlowerSubMenu: false,
      isOpenServiceAreasSubMenu: false
    })
  }

  handleClickOutside = e => {
    if (this.desktopmenu.contains(e.target)) {
      // console.log('inside')
    } else {
      this.setState({
        activeMenu: '',
        isOpenReedFlowerSubMenu: false,
        isOpenServiceAreasSubMenu: false
      })
    }
  }

  onSelectMenu = menu => {
    const { activeMenu } = this.state
    switch (menu) {
      case 'flower':
        if (activeMenu === 'flower') {
          this.setState({
            activeMenu: '',
            isOpenReedFlowerSubMenu: false,
            isOpenServiceAreasSubMenu: false
          })
        } else {
          this.setState({
            activeMenu: menu,
            isOpenReedFlowerSubMenu: true,
            isOpenServiceAreasSubMenu: false
          })
        }
        break
      case 'service-areas':
        if (activeMenu === 'service-areas') {
          this.setState({
            activeMenu: '',
            isOpenReedFlowerSubMenu: false,
            isOpenServiceAreasSubMenu: false
          })
        } else {
          this.setState({
            activeMenu: menu,
            isOpenReedFlowerSubMenu: false,
            isOpenServiceAreasSubMenu: true
          })
        }
        break
      default:
        this.setState({
          activeMenu: menu
        })
        break
    }
  }

  onClickSubMenu = () => {
    this.setState({
      activeMenu: 'flower',
      isOpenReedFlowerSubMenu: false,
      isOpenServiceAreasSubMenu: false
    })
  }

  onScrollTo = ele => {
    const offset = document.getElementById('navbar').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  render() {
    const { activeMenu, isOpenReedFlowerSubMenu } = this.state

    return (
      <div className="main-menu-wrapper" ref={node => (this.desktopmenu = node)}>
        <div className="main-menu">
          <div
            className={`${activeMenu === 'flower' ? 'active' : ''} menu-item-box`}
            onClick={() => this.onSelectMenu('flower')}
            onKeyDown={() => this.onSelectMenu('flower')}
          >
            <p>พวงหรีดดอกไม้สด</p>
          </div>
          {isOpenReedFlowerSubMenu && (
            <ReedFlower className="d-sub-menu-wrapper" onClickSubMenu={this.onClickSubMenu} />
          )}
        </div>
        <div className="vertical-line" style={{ alignSelf: 'center' }}></div>
        <div className="main-menu">
          <div
            className={`${activeMenu === 'fan' ? 'active' : ''} menu-item-box`}
            onClick={() => {
              this.onSelectMenu('fan')
              this.onScrollTo('productF')
            }}
            onKeyDown={() => {
              this.onSelectMenu('fan')
              this.onScrollTo('productF')
            }}
          >
            <p>พวงหรีดพัดลม</p>
          </div>
        </div>
        <div className="vertical-line" style={{ alignSelf: 'center' }}></div>
        <div className="main-menu">
          <div
            className={`${activeMenu === 'service-areas' ? 'active' : ''} menu-item-box`}
            onClick={() => this.onSelectMenu('service-areas')}
            onKeyDown={() => this.onSelectMenu('service-areas')}
          >
            <p>พื้นที่จัดส่ง</p>
          </div>
          {activeMenu === 'service-areas' && (
            <ServiceAreas className="d-sub-menu-wrapper" onClickSubMenu={this.onClickSubMenu} />
          )}
        </div>
        {/* <div className="vertical-line" style={{ alignSelf: 'center' }}></div>
        <div className="main-menu">
          <div className={`"menu-item-box"`}>
            <p>เกี่ยวกับเรา</p>
          </div>
        </div> */}
      </div>
    )
  }
}
