import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import Scroll from 'react-scroll'

import './style.scss'

const scroller = Scroll.scroller
export default class Menu extends Component {
  onScrollTo = ele => {
    const offset = document.getElementById('mobile-menu').offsetHeight
    scroller.scrollTo(ele, {
      duration: 1500,
      // delay: 100,
      smooth: 'easeInOutQuad',
      offset: -offset // Scrolls to element -155 pixels up the page (minus for fix main menu) // FIXME: refactor to get heigth of the header instead
    })
  }

  render() {
    const { onClickMenu, activeMenu, isStickyMenuMobile } = this.props
    if (isStickyMenuMobile)
      return (
        <Fade duration={500}>
          <div
            className={`mobile slide-menu ${isStickyMenuMobile ? 'sticky' : ''}`}
            id="mobile-menu"
          >
            <div
              className="slide-menu-item cat-a"
              onClick={() => {
                onClickMenu('cat-a')
                this.onScrollTo('productA')
              }}
            >
              <p className={`${activeMenu === 'cat-a' ? 'active' : ''}`} id={'cat-a'}>
                <span>พวงหรีด</span>
                <br />
                แบบประหยัด
              </p>
            </div>
            <div
              className="slide-menu-item cat-b"
              onClick={() => {
                onClickMenu('cat-b')
                this.onScrollTo('productB')
              }}
            >
              <p className={`${activeMenu === 'cat-b' ? 'active' : ''}`} id={'cat-b'}>
                <span>พวงหรีด</span>
                <br />
                แบบมาตรฐาน
              </p>
            </div>
            <div
              className="slide-menu-item cat-p"
              onClick={() => {
                onClickMenu('cat-p')
                this.onScrollTo('productPremium')
              }}
            >
              <p className={`${activeMenu === 'cat-p' ? 'active' : ''}`} id={'cat-p'}>
                <span>พวงหรีด</span>
                <br />
                แบบพรีเมียม
              </p>
            </div>
            <div
              className="slide-menu-item cat-f"
              onClick={() => {
                onClickMenu('cat-f')
                this.onScrollTo('productF')
              }}
            >
              <p className={`${activeMenu === 'cat-f' ? 'active' : ''}`} id={'cat-f'}>
                <span>พวงหรีด</span>
                <br />
                พัดลม
              </p>
            </div>
          </div>
        </Fade>
      )
    else return ''
  }
}
