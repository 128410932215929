import React, { Component } from 'react'
import './style.scss'

export default class ServiceAreas extends Component {
  render() {
    return (
      <div className="sub-menu-wrapper">
        <div className="sub-menu-box">
          <a className="sub-menu-item" href="/">
            หน้าหลัก
          </a>
        </div>
      </div>
    )
  }
}
