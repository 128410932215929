import React, { Component } from 'react'

import Bangkok from './ServiceAreasRegion/Bangkok'
import NorthEast from './ServiceAreasRegion/NorthEast'
import South from './ServiceAreasRegion/South'
import Central from './ServiceAreasRegion/Central'
import North from './ServiceAreasRegion/North'
import East from './ServiceAreasRegion/East'
import West from './ServiceAreasRegion/West'
import './style.scss'

export default class ServiceAreas extends Component {
  render() {
    const { className, onClickSubMenu } = this.props
    return (
      <div className={`${className} sub-menu-wrapper`}>
        <div className="sub-menu-box">
          <Bangkok onClickSubMenu={onClickSubMenu} />
          <NorthEast onClickSubMenu={onClickSubMenu} />
          <South onClickSubMenu={onClickSubMenu} />
          <Central onClickSubMenu={onClickSubMenu} />
          <North onClickSubMenu={onClickSubMenu} />
          <East onClickSubMenu={onClickSubMenu} />
          <West onClickSubMenu={onClickSubMenu} />
        </div>
      </div>
    )
  }
}
